// Here we import all of the scripts

require('lazysizes')
require('./social_media_placeholders')
require('./accordion')
require('./carousel')
require('./custom_tcf')
require('./markets-accordion')
require('./search')
require('./dropdown')
require('./side_menu')
require('./regions_dropdown')
require('./newsletter')
require('./breaking_news')
require('./date')
require('./top_navigation_slider')
require('./main_navigation_slider')
require('./back_to_top')
require('./search_topics')
require('./session')
require('./image_expandable_toggle')
require('./sticky_header')
require('./infobox_toggle')
require('./DISABLED_paywall_layout_campaign')
require('./cancel-subscription-form')
require('./filter_authors')
require('./load_more')
require('./promotion_banner_campaign')
