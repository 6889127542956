const $ = require('jquery')

$(function onDocumentReady() {
  const intervalId = setInterval(() => {
    if (window.userData) {
      clearInterval(intervalId)
      setupPaywallLayout()
    }
  }, 100)

  function setupPaywallLayout() {
    const ogTypeContent = $('meta[property="og:type"]')?.attr('content')
    const paywall = $('meta[name="paywall"]')?.attr('content')
    const documentId = $('meta[name="documentid"]')?.attr('content')

    if (window.userData.status === 'anonymous') {
      $.get(`/api/promotion-banner?documentId=${documentId}`)
        .then(response => {
          if (response && response.metadata && response.html) {
            // Anonymous + Plus Articles
            if (response.metadata.scenario === 'anonymous_plus_articles') {
              if (ogTypeContent === 'article' && paywall === 'plus') {
                handleTemplateLayout(response)
              }
            }

            // Anonymous + Homepage, Overview, Article Pages
            else if (response.metadata.scenario === 'anonymous_everywhere') {
              if (
                ogTypeContent === 'page' ||
                ogTypeContent === 'subcategory-page' ||
                ogTypeContent === 'category-page' ||
                ogTypeContent === 'article'
              )
                handleTemplateLayout(response)
            }

            // Anonymous + Plus/Overview Page
            else if (response.metadata.scenario === 'anonymous_plus_overview') {
              if (
                (ogTypeContent === 'article' && paywall === 'plus') ||
                ogTypeContent === 'subcategory-page' ||
                ogTypeContent === 'category-page'
              ) {
                handleTemplateLayout(response)
              }
            }

            // Anonymous + All Articles
            else if (response.metadata.scenario === 'anonymous_all_articles') {
              if (ogTypeContent === 'article') {
                handleTemplateLayout(response)
              }
            }

            // Anonymous + Free Articles
            else if (response.metadata.scenario === 'anonymous_free_articles') {
              if (
                ogTypeContent === 'article' &&
                (paywall === 'metered' || paywall === 'free')
              ) {
                handleTemplateLayout(response)
              }
            }
          } else {
            console.info('No active promotion banner available.')
          }
        })
        .catch(error => {
          console.error('Error while fetching promotion banner:', error)
        })
    }
  }

  function handleTemplateLayout(response) {
    const template = response.metadata.layer

    if (template.endsWith('flyin')) {
      $('body').prepend(response.html)
    } else if (template.endsWith('breakingbar')) {
      $('.fp-main-header__bottom').after(response.html)
    } else if (template.endsWith('bubble')) {
      $('.fp-header .fp-main-header__links').first().append(response.html)
    } else if (template.endsWith('overlay')) {
      $('body')
        .attr('scroll', 'no')
        .addClass('scrolling-disabled')
        .append(
          `<div class="paywall_overlay">${response.html}</div><div class="container-grayed-background"></div>`,
        )
    }

    setupCloseButton()
    setupToggleButton()
  }

  let bSHowAvFlyin = true
  $(document).on('scroll', () => {
    var y = $(this).scrollTop()
    if (y > 10 && bSHowAvFlyin) {
      $('.fp-paywall-layer-flyin--js').fadeIn()
    } else {
      $('.fp-paywall-layer-flyin--js').fadeOut()
    }
  })

  function setupCloseButton() {
    $('.fp-paywall-layer-flyin--closebutton').on('click', () => {
      $('.fp-paywall-layer-flyin--js').remove()
    })
    $('.fp-paywall-layer-breakingbar--closebutton').on('click', () => {
      $('.fp-paywall-layer-breakingbar--js').fadeOut()
    })
    $('.fp-paywall-layer-bubble--closebutton').on('click', () => {
      $('.fp-paywall-layer-bubble--js').fadeOut()
    })
    $('.fp-paywall-layer-overlay--closebutton').on('click', () => {
      $('.paywall_overlay').fadeOut()
      $('.container-grayed-background').fadeOut()
      $('body').removeAttr('scroll').removeClass('scrolling-disabled')
    })
  }

  function setupToggleButton() {
    $('.fp-paywall-layer-flyin-light--button').on('click', () => {
      const flyinDialog = $('#fp-paywall-layer-light-flyin-wrapper')
      const arrow = $('.fp-paywall-layer-flyin-light--chevron-arrow path')
      const arrowPathDown = 'M18 9L12 15L6 9'
      const arrowPathUp = 'M6 15L12 9L18 15'
      const actionBtnOnCollapsed = $(
        '.fp-paywall-layer-light-flyin--action-link',
      )

      // If the paywall is collapsed then expand it
      if (flyinDialog.hasClass('collapsed')) {
        flyinDialog.removeClass('collapsed')
        arrow.attr('d', arrowPathDown)
        actionBtnOnCollapsed.hide()
      } else {
        // Else if it is expanded just collapse it
        flyinDialog.addClass('collapsed')
        arrow.attr('d', arrowPathUp)
        actionBtnOnCollapsed.show()
      }
    })
  }
})
